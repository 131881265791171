export default function App() {
	return (
		<main>
			<div className='overlay'></div>
			<div className='container'>
				<div
					id='main'
					className='d-flex justify-content-center align-items-center p-2'
				>
					<div className='container text-center'>
						<h1 className='mb-3'>Coming Soon...</h1>
						<div className='spinner-grow' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
						<p className='mt-3'>In the meantime, drop us a line!</p>
						<a href='mailto:brian@literallypossible.io' className='button'>
							Contact Us
						</a>
					</div>
				</div>
				<div id='footer' className='container text-center text-white'>
					&copy;2025 Literally Possible, LLC. All rights reserved.
				</div>
			</div>
		</main>
	);
}
